import app from  'firebase/app'
import 'firebase/auth'
import 'firebase/firebase-firestore'

const firebaseConfig = {
    apiKey: process.env.API_KEY,
    authDomain: process.env.AUTH_DOMAIN,
    databaseURL: process.env.DATABASE_URL,
    projectId: process.env.PROJECT_ID,
    storageBucket: process.env.STORAGE_BUCKET,
    messagingSenderId: process.env.MESSAGING_SENDER_ID,
    appId: process.env.APP_ID
  };

  /* 
  API_KEY=AIzaSyCyt6LjdnoXya4Qiw-epJWw7xAANeoXCKw
AUTH_DOMAIN=rigabeeweb.firebaseapp.com
DATABASE_URL=https://rigabeeweb.firebaseio.com
PROJECT_ID=rigabeeweb
STOREAGE_BUCKET=rigabeeweb.appspot.com
MESSAGING_SENDER_ID=795050335004
APP_ID=1:795050335004:web:c1a8eef226f8bd8b
  */

  class Firebase {
      constructor(){
        // app.initializeApp();
        app.initializeApp(firebaseConfig);
        this.auth = app.auth();
        this.db = app.firestore();
      }

      async login(email,password){
        await this.auth.signInWithEmailAndPassword(email,password)
        .then(function(user){
          if (user){
            if(user.user.emailVerified) {
              console.log('Email Verified!');
            }
          }
        })
        ;
        return this.auth.currentUser;
      }

      logout(){
          this.auth.signOut();
      }

      passwordReset(email){
        this.auth.sendPasswordResetEmail(email);
    }

      async register(firstName, lastName, email, password, userRole){
       
        const name = firstName + ' ' + lastName;

        try {
          var user = await this.auth.createUserWithEmailAndPassword(email, password);
          if(user){
            console.log(user);
            var CurUser = this.auth.currentUser;
            if(CurUser){
              await CurUser.sendEmailVerification()
              .then(function(){
                 console.log('Email sent');
               //  var UsersTbl = this.db.collection('users');
               //  if(UsersTbl){
               //   UsersTbl.doc(user.user.uid).set({
               //     User_Name: name,
               //     User_Role: userRole,
               //     Email: user.user.email,
               //     Terms_Accepted_TS: new Date()
                //   })
                // }

              }).catch(function(error) {
                 var errorCode = error.code;
                 var errorMessage = error.message;
             
                 console.log(errorCode, errorMessage);
              });
            }
          }

        } catch(error) {
          var errorCode = error.code;
          var errorMessage = error.message;
      
          console.log(errorCode, errorMessage);
        }

        return this.auth.currentUser.updateProfile({displayName: name});
      }

      isInitialized(){
        return new Promise(resolve => {
          this.auth.onAuthStateChanged(resolve)
        }
          )
      }

      getCurrentUserName(){
          return this.auth.currentUser && this.auth.currentUser.displayName
      }
  }

  export default new Firebase();